var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('loading',{attrs:{"active":_vm.loading,"can-cancel":false,"background-color":"#fff","is-full-page":false},on:{"update:active":function($event){_vm.loading=$event}}}),_vm._l((_vm.errors),function(error_message){return (_vm.errors.length > 0)?_c('div',[_c('CAlert',{attrs:{"color":"danger","closeButton":""}},[_vm._v(_vm._s(error_message))])],1):_vm._e()}),_c('section',[_c('CCard',[_c('CCardHeader',[_c('strong',[_vm._v("Filtros")]),_c('div',{staticClass:"card-header-actions"},[_c('b-button',{on:{"click":function($event){return _vm.fetchClientsCreated()}}},[_vm._v("Consultar")])],1)]),_c('CCardBody',[_c('b-row',[_c('b-col',{attrs:{"lg":"3","offset-lg":"3","sm":"12"}},[_c('b-form-group',[_c('small',[_vm._v("Fechas")]),_c('v-date-picker',{attrs:{"mode":"range"},model:{value:(_vm.selectedDate),callback:function ($$v) {_vm.selectedDate=$$v},expression:"selectedDate"}})],1)],1),_c('b-col',{attrs:{"lg":"3","sm":"12"}},[_c('b-form-group',[_c('small',[_vm._v("Ciudades")]),_c('v-select',{attrs:{"options":_vm.cities,"placeholder":"Selecciona tu ciudad"},model:{value:(_vm.citySelected),callback:function ($$v) {_vm.citySelected=$$v},expression:"citySelected"}})],1)],1)],1)],1)],1)],1),_c('section',[_c('b-row',[_c('b-col',{staticStyle:{"text-align":"center"},attrs:{"lg":"6"}},[_c('CCard',[_c('CCardHeader',[_c('CButton',{directives:[{name:"c-popover",rawName:"v-c-popover",value:({
                header: 'Leyenda',
                content: 'Prospectos: total de registros creados en el módulo prospecto. Clientes nuevos: prospectos con registro en goguau.co (cliente)'
              }),expression:"{\n                header: 'Leyenda',\n                content: 'Prospectos: total de registros creados en el módulo prospecto. Clientes nuevos: prospectos con registro en goguau.co (cliente)'\n              }"}],attrs:{"color":"primary","variant":"ghost"}},[_vm._v(" Resumen de prospectos ")])],1),_c('CCardBody',[(_vm.datasets != null && _vm.labels != null)?_c('CChartBar',{attrs:{"datasets":_vm.datasets,"labels":_vm.labels,"options":_vm.options}}):_vm._e()],1)],1)],1),_c('b-col',{staticStyle:{"text-align":"center"},attrs:{"lg":"6"}},[_c('CCard',[_c('CCardHeader',[_c('CButton',{directives:[{name:"c-popover",rawName:"v-c-popover",value:({
                header: 'Leyenda',
                content: 'Detalle de prospectos por estados (flujo comercial)',
                placement: 'left'
              }),expression:"{\n                header: 'Leyenda',\n                content: 'Detalle de prospectos por estados (flujo comercial)',\n                placement: 'left'\n              }"}],attrs:{"color":"primary","variant":"ghost"}},[_vm._v(" Detalle de prospectos ")])],1),_c('CCardBody',[(_vm.dataSetState && _vm.labelsState)?_c('CChartBar',{attrs:{"datasets":_vm.dataSetState,"labels":_vm.labelsState,"options":_vm.options}}):_vm._e()],1)],1)],1),_c('b-col',{staticStyle:{"text-align":"center"},attrs:{"lg":"6"}},[_c('CCard',[_c('CCardHeader',[_c('CButton',{directives:[{name:"c-popover",rawName:"v-c-popover",value:({
                header: 'Leyenda',
                content: 'Clientes registrados en goguau.co vs cuales de ellos tienen primer pedido.',
              }),expression:"{\n                header: 'Leyenda',\n                content: 'Clientes registrados en goguau.co vs cuales de ellos tienen primer pedido.',\n              }"}],attrs:{"color":"primary","variant":"ghost"}},[_vm._v(" Clientes registrados en plataforma ")])],1),_c('CCardBody',[(_vm.dataSetClientsRegistered && _vm.labelsClientesRegistered)?_c('CChartBar',{attrs:{"datasets":_vm.dataSetClientsRegistered,"labels":_vm.labelsClientesRegistered,"options":_vm.options}}):_vm._e()],1)],1)],1),_c('b-col',{staticStyle:{"text-align":"center"},attrs:{"lg":"6"}},[_c('CCard',[_c('CCardHeader',[_c('CButton',{directives:[{name:"c-popover",rawName:"v-c-popover",value:({
                header: 'Leyenda',
                content: 'clientes con primer pedido / Prospectos con registro en goguau (clientes nuevos gráfica 1)',
                placement: 'left'
              }),expression:"{\n                header: 'Leyenda',\n                content: 'clientes con primer pedido / Prospectos con registro en goguau (clientes nuevos gráfica 1)',\n                placement: 'left'\n              }"}],attrs:{"color":"primary","variant":"ghost"}},[_vm._v(" Efectividad cierre de prospectos ")])],1),_c('CCardBody',[(_vm.dataSetState && _vm.labelsState)?_c('CChartLine',{attrs:{"pointed":"","border-color":"warning","datasets":_vm.dataSetPercLeads,"labels":_vm.labelsPercLeads,"options":_vm.options}}):_vm._e()],1)],1)],1)],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }