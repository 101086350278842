<template>
  <div>
    <loading
      :active.sync="loading"
      :can-cancel="false"
      background-color="#fff"
      :is-full-page="false"
    ></loading>
    <div v-for="error_message in errors" v-if="errors.length > 0">
      <CAlert color="danger" closeButton>{{ error_message }}</CAlert>
    </div>

    <!-- Filters -->
    <section>
      <CCard>
        <CCardHeader>
          <strong>Filtros</strong>
          <div class="card-header-actions">
            <b-button @click="fetchClientsCreated()">Consultar</b-button>
          </div>
        </CCardHeader>
        <CCardBody>
          <b-row>
            <b-col lg="3" offset-lg="3" sm="12">
              <b-form-group>
                <small>Fechas</small>
                <v-date-picker mode="range" v-model="selectedDate" />
              </b-form-group>
            </b-col>
            <b-col lg="3" sm="12">
              <b-form-group>
                <small>Ciudades</small>
                <v-select
                  v-model="citySelected"
                  :options="cities"
                  placeholder="Selecciona tu ciudad"
                >
                </v-select>
              </b-form-group>
            </b-col>
          </b-row>
        </CCardBody>
      </CCard>
    </section>

    <!-- Charts -->
    <section>
      <b-row>
        <b-col lg="6" style="text-align: center">
          <CCard>
            <CCardHeader>
              <CButton
                color="primary"
                variant="ghost" 
                v-c-popover="{
                  header: 'Leyenda',
                  content: 'Prospectos: total de registros creados en el módulo prospecto. Clientes nuevos: prospectos con registro en goguau.co (cliente)'
                }"
              >
                Resumen de prospectos
              </CButton>
            </CCardHeader>
            <CCardBody>
              <CChartBar
                v-if="datasets != null && labels != null"
                :datasets="datasets"
                :labels="labels"
                :options="options"
              />
            </CCardBody>
          </CCard>
        </b-col>
        <b-col lg="6" style="text-align: center">
          <CCard>
            <CCardHeader>
              <CButton
                color="primary"
                variant="ghost" 
                v-c-popover="{
                  header: 'Leyenda',
                  content: 'Detalle de prospectos por estados (flujo comercial)',
                  placement: 'left'
                }"
              >
                Detalle de prospectos
              </CButton>
            </CCardHeader>
            <CCardBody>
              <CChartBar
                v-if="dataSetState && labelsState"
                :datasets="dataSetState"
                :labels="labelsState"
                :options="options"
              />
            </CCardBody>
          </CCard>
        </b-col>
        <b-col lg="6" style="text-align: center">
          <CCard>
            <CCardHeader>
              <CButton
                color="primary"
                variant="ghost" 
                v-c-popover="{
                  header: 'Leyenda',
                  content: 'Clientes registrados en goguau.co vs cuales de ellos tienen primer pedido.',
                }"
              >
                Clientes registrados en plataforma
              </CButton>
            </CCardHeader>
            <CCardBody>
              <CChartBar
                v-if="dataSetClientsRegistered && labelsClientesRegistered"
                :datasets="dataSetClientsRegistered"
                :labels="labelsClientesRegistered"
                :options="options"
              />
            </CCardBody>
          </CCard>
        </b-col>
        <b-col lg="6" style="text-align: center">
          <CCard>
            <CCardHeader>
              <CButton
                color="primary"
                variant="ghost" 
                v-c-popover="{
                  header: 'Leyenda',
                  content: 'clientes con primer pedido / Prospectos con registro en goguau (clientes nuevos gráfica 1)',
                  placement: 'left'
                }"
              >
                Efectividad cierre de prospectos
              </CButton>
            </CCardHeader>
            <CCardBody>
              <CChartLine
                pointed
                border-color="warning"
                v-if="dataSetState && labelsState"
                :datasets="dataSetPercLeads"
                :labels="labelsPercLeads"
                :options="options"
              />
            </CCardBody>
          </CCard>
        </b-col>
      </b-row>
    </section>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import { CChartBar, CChartDoughnut, CChartLine } from "@coreui/vue-chartjs";
import { DatePicker } from "v-calendar";
import moment from "moment";
moment.locale("es");

const today = new Date();

export default {
  name: "adminClients",
  components: {
    Loading,
    CChartBar,
    CChartDoughnut,
    CChartLine,
    "v-date-picker": DatePicker
  },
  data() {
    return {
      loading: true,
      errors: [],
      dateStart: new Date(today.getFullYear(), today.getMonth(), 1),
      dateEnd: new Date(today.getFullYear(), today.getMonth(), 28),
      datasets: null,
      labels: null,
      selectedDate: {
        start: new Date(today.getFullYear(), today.getMonth(), 1),
        end: new Date(today.getFullYear(), today.getMonth(), 28)
      },
      cities: [{ value: 0, label: "Todas" }],
      citySelected: { value: 0, label: "Todas" },
      options: {
        responsive: true,
        legend: { position: "bottom" },
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true
              }
            }
          ]
        },
        tooltips: {
          enabled: true
        },

        animation: {
          duration: 500,
          easing: "easeOutQuart",
          onComplete: function() {
            var ctx = this.chart.ctx;
            ctx.font = Chart.helpers.fontString(
              Chart.defaults.global.defaultFontFamily,
              "normal",
              Chart.defaults.global.defaultFontFamily
            );
            ctx.textAlign = "center";
            ctx.textBaseline = "bottom";

            this.data.datasets.forEach(function(dataset) {
              for (var i = 0; i < dataset.data.length; i++) {
                var model =
                    dataset._meta[Object.keys(dataset._meta)[0]].data[i]._model,
                  scale_max =
                    dataset._meta[Object.keys(dataset._meta)[0]].data[i]._yScale
                      .maxHeight;
                ctx.fillStyle = "#444";
                var y_pos = model.y - 5;
                // Make sure data value does not get overflown and hidden
                // when the bar's value is too close to max value of scale
                // Note: The y value is reverse, it counts from top down
                if ((scale_max - model.y) / scale_max >= 0.93)
                  y_pos = model.y + 20;
                ctx.fillText(dataset.data[i], model.x, y_pos);
              }
            });
          }
        }
      },
      optionsLine: {
        tooltips: {
          enabled: false
        },
        animation: {
          duration: 500,
          easing: "easeOutQuart",
          onComplete: function() {
            var ctx = this.chart.ctx;
            ctx.font = Chart.helpers.fontString(
              Chart.defaults.global.defaultFontFamily,
              "normal",
              Chart.defaults.global.defaultFontFamily
            );
            ctx.textAlign = "center";
            ctx.textBaseline = "bottom";

            this.data.datasets.forEach(function(dataset) {
              for (var i = 0; i < dataset.data.length; i++) {
                var model =
                    dataset._meta[Object.keys(dataset._meta)[0]].data[i]._model,
                  scale_max =
                    dataset._meta[Object.keys(dataset._meta)[0]].data[i]._yScale
                      .maxHeight;
                ctx.fillStyle = "#444";
                var y_pos = model.y - 5;
                // Make sure data value does not get overflown and hidden
                // when the bar's value is too close to max value of scale
                // Note: The y value is reverse, it counts from top down
                if ((scale_max - model.y) / scale_max >= 0.93)
                  y_pos = model.y + 20;
                ctx.fillText(dataset.data[i], model.x, y_pos);
              }
            });
          }
        },
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true
              }
            }
          ]
        }
      },
      labelsState: null,
      dataSetState: null,
      dataSetClientsRegistered: null,
      labelsClientesRegistered: null,
      dataSetPercLeads: null,
      labelsPercLeads: null
    };
  },
  created() {
    this.userProfile = JSON.parse(this.$store.getters.userProfile);

    if (this.userProfile.status.id == 2) {
      this.fetchCities();
      // this.fetchClientsCreated();
      
    } else {
      this.errors.push("No tienes acceso a este módulo");
    }
    this.loading = false;
  },

  methods: {
    fetchCities() {
      let getCities = this.$firebase.functions().httpsCallable("getCities");
      getCities()
        .then(response => {
          if (response.data != null) {
            let cities = response.data.data;
            if (this.userProfile.acceso.id == 1) {
              //owner
              cities.map((city, i) => {
                this.cities.push({
                  value: city.id,
                  label: city.nombre
                });
              });
            } else if (this.userProfile.acceso.id == 2) {
              //admin
              let cityFound = cities.find(
                city => city.nombre == this.userProfile.ciudad
              );
              this.citySelected = {
                value: cityFound.id,
                label: cityFound.nombre
              };
              this.cities = [
                {
                  value: cityFound.id,
                  label: cityFound.nombre
                }
              ];
            }

            this.fetchClientsCreated();
          }
        })
        .catch(error => console.log(error));
    },
    fetchClientsCreated() {
      this.loading = true;
      this.dateStart =
        moment(this.selectedDate.start).format("yyyy-MM-DD") + "T00:00:00.000Z";
      this.dateEnd =
        moment(this.selectedDate.end).format("yyyy-MM-DD") + "T00:00:00.000Z";
      let payload = {
        dateStart: this.dateStart,
        dateEnd: this.dateEnd,
        city: this.citySelected
      };
      console.log('payload: ', payload)
      let getClientsCreated = this.$firebase.functions().httpsCallable("getClientsCreatedKPI");
      getClientsCreated(payload)
        .then(response => {
          console.log('asdfa')
          console.log(response)
          let data = response.data;
          //  Leads and new clients kpi
          this.datasets = data.leadsKPI.dataSet;
          this.labels = data.leadsKPI.labels;
          //  Leads state kpi
          this.dataSetState = data.leadsStateKPI.dataSet;
          this.labelsState = data.leadsStateKPI.labels;
          //  new clients registered in platform
          this.dataSetClientsRegistered = data.clientsRegisteredKPI.dataSet;
          this.labelsClientesRegistered = data.clientsRegisteredKPI.labels;
          //  Percentage of efectivity at closing leads
          this.dataSetPercLeads = data.efectivenessKPI.dataSet;
          this.labelsPercLeads = data.leadsStateKPI.labels;
        })
        .catch(error => console.log(error))
        .finally(_ => (this.loading = false));
    }
  }
};
</script>
